import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import logonew from '../../assets/images/resource/favicon.png'

import silenceGolden from '../../assets/images/resource/silenceGolden.jpg'
import lessential from '../../assets/images/resource/lessential.jpg'
import starryNight from '../../assets/images/resource/starryNight.jpg'
import nectarDevie from '../../assets/images/resource/garden.jpg'
import garden from '../../assets/images/resource/nectarDeVie.jpg'
import broken2 from '../../assets/images/resource/brokenheart.jpg'
import whispering from '../../assets/images/resource/whispering.jpg'
import matcha from '../../assets/images/resource/matcha.jpg'
import lullaby from '../../assets/images/resource/lullaby.jpg'
import nothing from '../../assets/images/resource/nothing.jpg'

import autumn from '../../assets/images/resource/2024/A1.Autumn_Leaves_.jpg'
import free from '../../assets/images/resource/2024/A1.Break_Free.jpg'
import hito from '../../assets/images/resource/2024/A1.Hitomebore.jpg'
import heal from '../../assets/images/resource/2024/A1.heal.jpg'
import mm from '../../assets/images/resource/2024/A1.mm.jpg'
import mori from '../../assets/images/resource/2024/A1.mori.jpg'
import nos from '../../assets/images/resource/2024/A1.nos.jpg'
import summer from '../../assets/images/resource/2024/A1.summer.jpg'
import sweet from '../../assets/images/resource/2024/A1.sweet.jpg'
import way from '../../assets/images/resource/2024/A1.way.jpg'



// import bgone from '../../assets/images/background/bg-1.png'
// import five from '../../assets/images/resource/wine.png'
// import Bgtwo from '../../assets/images/background/bg-2.png'

const NewSignature = [
    {
        name: 'Autumn Leaves',
        price: '229.000',
        base: '',
        image: autumn,

        description: '',
    },
    {
        name: 'Break Free',
        price: '229.000',
        image: free,
        base: '',
        description: '',
    },

    {
        name: 'Hitomebore',
        price: '229.000',
        image: hito,
        base: '',
        description: '',
    },
    {
        name: 'Memento mori',
        price: '229.000',
        image: mori,
        base: '',
        description: '',
    },
    {
        name: 'Nostalgia',
        price: '229.000',
        image: nos,
        base: '',
        description: '',
    },
    {
        name: 'Summer Fling',
        price: '229.000',
        image: summer,
        base: '',
        description: '',
    },
    {
        name: 'Sweet Dreams',
        price: '229.000',
        image: sweet,
        base: '',
        description: '',
    },
    {
        name: "Way of the water",
        price: '229.000',
        image: way,
        base: '',
        description: '',
    },
    {
        name: 'Mơ Màng',
        price: '229.000',
        image: mm,
        base: 'Non Alcoholic Mocktail',
        description: '',
    },
    {
        name: "It's take thyme to heal",
        price: '229.000',
        image: heal,
        base: 'Non Alcoholic Mocktail',
        description: '',
    },
]

const Wine = [
    {
        name: 'Nectar de Vie',
        price: '229.000',
        base: 'Tequila || Sweet, bitter, refreshing, aromatic, with a hint of spice.',
        image: nectarDevie,

        description: 'Tequila, Coffee, Sweet basil, Orange bitter, Kaffir Lime Cordial',
    },
    {
        name: 'Silence is golden',
        price: '229.000',
        image: silenceGolden,
        base: 'Tequila || Sweet, refreshing, aromatic, and creamy.',
        description: 'Tequila, Thai matcha, Wasabi, Orange flower water',
    },

    {
        name: 'Whispering of the woods',
        price: '229.000',
        image: whispering,
        base: 'Bourbon || Earthy sandalwood, fresh red fruit notes, and a subtle, refreshing aroma.',
        description: 'Bourbon, Sandalwood, Osmanthus, Earlgrey, Cab Sauv, Lime Cordial',
    },
    {
        name: "L'essential",
        price: '229.000',
        image: lessential,
        base: 'Vodka || Balanced sweetness, jasmine aroma, subtle spiciness, and a touch of refreshing zing.',
        description: 'Vodka, Jasmine cordial, Cardamom, Fennel, Sour apple',
    },
    {
        name: 'Starry Night',
        price: '229.000',
        image: starryNight,
        base: 'Gin || Herbal blend, delicate violet aroma, and a hint of citrus candy in Gin-based delight.',
        description: 'Gin, Basil, Thyme, Oregano, Creme de Violet, Lemon bitter, Lime cordial',
    },
    {
        name: 'Lullaby Martini',
        price: '229.000',
        base: 'Gin',
        image: lullaby,
        description: 'Gin, Bianco vermouth, Chamomile, Licorice, Goji berries',
    },
    {
        name: 'Boulevard Of Broken Dreams',
        price: '229.000',
        image: broken2,
        base: 'Bourbon || Slightly sweet, gentle bitterness, with a touch of spiced floral aroma from Ylang Ylang tea',
        description: 'Bourbon, Campari, Sweet Vermouth, Ylang Ylang Tea,  Lemon & Rasperry Cordial',
    },
    {
        name: 'Garden of words',
        price: '229.000',
        image: garden,
        base: 'Rum || Spicy osmanthus, mild bitterness, and sweet-sour harmony with a hint of floral bouquet',
        description: 'Rum, Hibiscus, Osmanthus, Coconut Liquor, Longan Cordial, Florals Bun',
    },
    {
        name: 'Nothing to say',
        price: '179.000',
        image: nothing,
        base: 'Non Alcoholic Mocktail',
        description: 'Vegan foam, Raspberry, beetroot, lime',
    },
    {
        name: "Swipe to match'a",
        price: '179.000',
        image: matcha,
        base: 'Non Alcoholic Mocktail',
        description: 'Vegan Foam, Rich Cream, Matcha, Osmanthus, Orangeflower Water, Lime, Soda',
    }
]


function Special() {
    const options = {
        items: 4,
        rewind: true,
        loop: true,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: true,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    const filterdMenu = Wine;

    return (
        <>
            <section id="menu-section" className="special-offer" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <div className="outer-container" style={{ paddingBottom: 0 }}>
                    <div className="auto-container plausible-event-name=Signatures-click">
                        <div className="title-box centered">
                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Signature, Cocktail, Mocktails' />
                            </div>
                            <h1>New Signatures </h1>
                        </div>
                        <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>
                            {
                                NewSignature.map(it => {
                                    return (
                                        <div className="offer-block-two margin-top">
                                            <div className="iner-box">
                                                <div className="image">
                                                    <div>
                                                        <img src={it.image || logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Siganture" />
                                                    </div>
                                                </div>
                                                <h4>
                                                    <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'space-between' }}>
                                                        <div>{it.name}</div>
                                                        <div className="price">{it.price}</div>
                                                    </div>
                                                </h4>
                                                {/* <div className="text desc" style={{ display: 'flex' }}>{it.base}</div> */}
                                                <div className="text desc" style={{ display: 'flex', textAlign: 'left' }}>{it.description}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
                <div className="outer-container" style={{ paddingBottom: 0 }}>
                    <div className="auto-container plausible-event-name=Signatures-click">
                        <div className="title-box centered">
                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Signature, Cocktail, Mocktails' />
                            </div>
                            <h1>Vintage Signatures </h1>
                        </div>
                        <OwlCarousel className="dish-gallery-slider owl-carousel owl-theme" options={options}>
                            {
                                Wine.map(it => {
                                    return (
                                        <div className="offer-block-two margin-top">
                                            <div className="iner-box">
                                                <div className="image">
                                                    <div>
                                                        <img src={it.image || logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Siganture" />
                                                    </div>
                                                </div>
                                                <h4>
                                                    <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'space-between' }}>
                                                        <div>{it.name}</div>
                                                        <div className="price">{it.price}</div>
                                                    </div>
                                                </h4>
                                                {/* <div className="text desc" style={{ display: 'flex' }}>{it.base}</div> */}
                                                <div className="text desc" style={{ display: 'flex', textAlign: 'left' }}>{it.description}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                    <div className="tabs-box menu-tabs" style={{ marginTop: 20 }}>
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" style={{ paddingLeft: 15, marginBottom: 20 }}>
                                                        <div className="inner-box" style={{ paddingLeft: 0 }}>
                                                            {/* <div className="dish-image"><Link to="#"><img src={item.image || logonew} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div> */}
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{ color: 'rgb(228,197,144)' }}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#"><i>{item.base}</i></Link></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 === 0) return null;
                                                return (
                                                    <div className="dish-block" style={{ paddingLeft: 15, marginBottom: 20 }}>
                                                        <div className="inner-box" style={{ paddingLeft: 0 }}>
                                                            {/* <div className="dish-image"><Link to="#"><img src={item.image || logonew} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div> */}
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{ color: 'rgb(228,197,144)' }}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#"><i>{item.base}</i></Link></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>

                    {/* <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}
                    <div style={{ marginTop: 30 }}></div>

                    {/* <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}

                    <div className="auto-container">


                    </div>

                </>
            </section>


        </>

    )
}

export default Special
